<template>
  <div>
    <NavBar />
    <section
      class="relative py-10 bg-white"
    >
    </section>
    <section
      class="relative pt-20 pb-20 bg-[#151515]"
    >
      <div
        class="w-full max-w-lg md:max-w-2xl lg:max-w-4xl px-5 lg:px-11 mx-auto max-md:px-4 flex flex-col items-center justify-center"
      >
        <h1
          class="text-white text-center font-semibold text-4xl min-[500px]:text-5xl leading-tight mb-2 font-josefin"
        >
          Our Latest Blog
        </h1>
        <h3 class=" text-[#7F7F7F] text-center w-[80%]">
          TALAB is your ultimate guide to luxury living in the capital. From the best food & drink, fashion and beauty to exclusive events.
        </h3>
      </div>
    </section>
    <div
      class="relative flex min-h-screen flex-col overflow-hidden py-6 sm:py-12"
    >
      <Transition name="slide-right">
        <div class="min-h-28" v-if="!isLoading">
          <div class="max-w-screen-lg mx-auto py-4 px-4 lg:px-0">
            <div class="flex gap-3 justify-center flex-wrap">
              <BlogItem
                v-for="post in blogs"
                :key="post.id"
                :post="post"
                class="lg:w-[30%] w-full"
              />
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
  <Footer />
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useBlog } from '@/composables/useBlog';
import BlogItem from '@/components/BlogItem.vue';
import Footer from '@/components/Footer.vue';
import NavBar from '@/components/NavBar.vue';

const { blogs, getAllBlogs, isLoading } = useBlog();

onMounted(() => {
  getAllBlogs();
});
</script>
