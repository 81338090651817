<script setup lang="ts">
import { computed, ref } from 'vue';
import * as amplitude from '@amplitude/analytics-browser';

const mobileMenuOpen = ref(false);

const navigation = [
  { name: 'Services', href: '/#services' },
  { name: 'How it works', href: '/#howitworks' },
  { name: 'Partners', href: '/#partners' },
  { name: 'Reviews', href: '/#reviews' },
  { name: 'Key features', href: '/#features' },
  { name: 'Blog', href: '/blog' },
];
function onDownloadBtnClick() {
  amplitude.track('download_btn_click');
}

const isAndroid = computed(() => /Android/i.test(navigator.userAgent));
</script>
<template>
  <header class="fixed w-full z-50">
    <nav
      class="flex items-center justify-between p-6 lg:px-8 backdrop-blur-md"
      aria-label="Global"
    >
      <div class="flex lg:flex-1">
        <a href="/" class="-m-1.5 p-1.5">
          <span class="sr-only">TALAB</span>
          <img
            class="h-5 w-auto"
            src="@/assets/gold-logo-transparent.png"
            alt=""
          />
        </a>
      </div>
      <div class="flex lg:hidden">
        <button
          type="button"
          class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          @click="mobileMenuOpen = !mobileMenuOpen"
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-12">
        <a
          v-for="item in navigation"
          :key="item.name"
          :href="item.href"
          class="text-sm font-semibold leading-6 text-gray-900"
          >{{ item.name }}</a
        >
      </div>
      <div class="hidden lg:flex lg:flex-1 lg:justify-end">
        <a
          :href="
            isAndroid
              ? 'https://play.google.com/store/apps/details?id=com.talabapp.talab&hl=en_GB'
              : 'https://apps.apple.com/us/app/talab-ultimate-student-app/id6503147995'
          "
          @click="onDownloadBtnClick"
          class="text-sm font-semibold leading-6 text-gray-900"
          >Download <span aria-hidden="true">&rarr;</span></a
        >
      </div>
    </nav>
    <Transition name="menu-slide">
      <div
        class="lg:hidden h-full fixed top-16 inset-y-0 right-0 z-50 w-full overflow-y-auto bg-[#151515] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
        v-if="mobileMenuOpen"
      >
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2">
              <a
                v-for="item in navigation"
                :key="item.name"
                :href="item.href"
                @click="mobileMenuOpen = false"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white"
                >{{ item.name }}</a
              >
              <a
                :href="
                  isAndroid
                    ? 'https://play.google.com/store/apps/details?id=com.talabapp.talab&hl=en_GB'
                    : 'https://apps.apple.com/us/app/talab-ultimate-student-app/id6503147995'
                "
                @click="onDownloadBtnClick"
                class="text-base font-semibold leading-6 text-white mt-16 py-2 px-3 -mx-3 block"
                >Download <span aria-hidden="true">&rarr;</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </header>
</template>

<style lang="scss" scoped>
.menu-slide-enter-active,
.menu-slide-leave-active,
.menu-slide-appear-active {
  transition: all 0.5s ease;
}

.menu-slide-enter-from,
.menu-slide-appear-from {
  transform: translateX(50%);
  opacity: 0;
}

.menu-slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
